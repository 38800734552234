import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import ProjectCategories from '../components/ProjectCategories'

export class BackToVideoProductionPageTemplate extends React.Component {
  render() {
    const { title, subtitle, videoProduction = [] } = this.props

    return (
      <div>
        <div className="section">
          <div className="wide">
            <div className="title">
              <Link className="back" to="/back-to-work/">
                Back to all
              </Link>
            </div>
            <ProjectCategories categories={videoProduction} from={`landing`}/>
          </div>
        </div>
      </div>
    )
  }
}

const BackToVideoProductionPage = ({ data: { page, videoProduction } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <BackToVideoProductionPageTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      videoProduction={videoProduction && videoProduction.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
    />
  </Layout>
)

export default BackToVideoProductionPage

export const pageQuery = graphql`
  query BackToVideoProductionPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      frontmatter {
        title
        subtitle
      }
    }

    videoProduction: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "projectCategories" } }, frontmatter: { parentcategory: { eq: "video production." } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            slug
            sectionOpener {
              image
              mobileImage
              preloadImage
            }
          }
        }
      }
    }
  }
`
